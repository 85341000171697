import React from 'react';
import './timeline.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IoCodeWorking } from 'react-icons/io5';

const Timeline = () => {
  const milestones = [
    {
      year: "2022 - 2023",
      title: "Founded",
      subtitle: "UofA Solar Car Club",
      description: "The club was established in 2022 with a mission to develop a solar car, aiming to compete in national and international solar car challenges.",
    },
    {
      year: "2023 - 2024",
      title: "Design Phase",
      subtitle: "Vehicle Conceptualization",
      description: "Focus on the design and conceptualization of the solar car, including aerodynamic modeling, solar panel selection, and initial prototype sketches.",
    },
    {
      year: "2024",
      title: "Prototyping",
      subtitle: "First Prototype Development",
      description: "Begin manufacturing the first prototype, testing components such as the chassis, electrical systems, and solar panels for efficiency and durability.",
    },
    {
      year: "2024 - 2025",
      title: "Manufacture and Test",
      subtitle: "Full-Scale Production",
      description: "Transition from prototyping to full-scale manufacturing of the solar car. Conduct extensive testing to ensure safety, reliability, and performance standards are met.",
    },
    {
      year: "2025",
      title: "FSGP Competition",
      subtitle: "Formula Sun Grand Prix",
      description: "Participate in the Formula Sun Grand Prix, a track event that tests solar car endurance and efficiency over several days of racing under varying conditions.",
    },
    {
      year: "2025 - 2026",
      title: "Innovation and Improvement",
      subtitle: "Next Generation Solar Car",
      description: "Leverage insights and experiences from previous competitions to design and develop the next generation solar car with enhanced features and performance.",
    },
    {
      year: "2026",
      title: "World Solar Challenge",
      subtitle: "Global Competition",
      description: "Compete in the World Solar Challenge, a biennial solar-powered car race over 3,022 km (1,878 mi) through the Australian Outback, from Darwin to Adelaide.",
    },
  ];
    // Custom styles for each VerticalTimelineElement to match the dark theme
    const customStyle = {
      background: '#1E1E1E', 
      color: '#12372A',
    };
  
    const customIconStyle = {
      background: '#F2CD00', 
      color: '#12372A', 
    };
  
  

  return (
    <section className="timeline">
      <VerticalTimeline>
        {milestones.map((milestone, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={customStyle}
            contentArrowStyle={{ borderRight: '5px solid  #F2CD00' }} // Gold color for the arrow
            date={milestone.year}
            iconStyle={customIconStyle}
            icon={<IoCodeWorking />}
          >
            <h3 className="vertical-timeline-element-title">{milestone.title}</h3>
            <h4 className="vertical-timeline-element-subtitle">{milestone.subtitle}</h4>
            <p>{milestone.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </section>
  );
};

export default Timeline;