import React, { useRef } from "react";

import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

function CarMesh(props) {
  const meshRef = useRef();
  const gltf = useGLTF("Concept_1.gltf");

  useFrame((_, delta) => {
    if (!!meshRef?.current) meshRef.current.rotation.x += delta;
  });

  return <primitive {...props} object={gltf.scene} />;
}

export default CarMesh;
