import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './navbar.css';
import logo from '../logo.svg';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar sticky-top navbar-expand-lg navbar-light ${scrolled ? 'scrolled' : ''}`}>
      <a className="navbar-brand" href="#main">
        <img src={logo} alt="Solar Car Club Logo" height="40"/>
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" >
          <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <a className="nav-item nav-link" href="#About">About Us</a>
          <a className="nav-item nav-link" href="#meetTheCar">Meet the Car</a>
          <a className="nav-item nav-link" href="#timeline">Timeline</a>
          <a className="nav-item nav-link" href="#Join">Join</a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
