import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import About from './about.js';
import Main from './main.js';
import MeetTheCar from "./MeetTheCar/MeetTheCar";
import Timeline from './timeline';
import Footer from './footer.js';
import Navbar from './navbar/navbar';
import ActionButtons from './buttons';
import Sponsor from './sponsor.js'
import logo from './logo.svg';

function App() {
  return (
    <>
      <div className="App">
        <Navbar />
        <div id='main' className="mainpage">
          <Main />
        </div>
        <div id='About' className="aboutpage">
          <About />
        </div>
        <div id='meetTheCar' className="meet-the-car">
          <MeetTheCar />
        </div>
        <div id='timeline' className="timeline-section">
          <Timeline />
        </div>
        <div id='Join'>
          <ActionButtons />
        </div>  
          <div id='Sponsor'>
            <Sponsor />
        </div>
        <div className="Footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
