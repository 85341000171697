import React from 'react';
import './main.css';
import logo from './Assets/Side.png'; 

function Main() {
  return (
    <div className="main-section">
      <div className="overlay"></div> 
      <div className="content-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="main-logo" />
        </div>
        <div className="main-content">
          <h1>Welcome to the Future</h1>
          <p>Innovating for a brighter, sustainable tomorrow.</p>
        </div>
      </div>
    </div>
  );
}

export default Main;
