import React, { useState, useEffect } from 'react';
import CarMesh from './CarMesh';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, PerspectiveCamera } from '@react-three/drei';
import './MeetTheCar.css'; // Import a CSS file for animations and more complex styling

function MeetTheCar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cameraPosition = isMobile ? [7, 5, 7] : [7, 5, 0];

  return (
    <div className="meetTheCar-container">
      <h1 className="meetTheCar-title">Meet The Car</h1>
      <div className="meetTheCar-contentContainer" style={{flexDirection: isMobile ? 'column' : 'row'}}>
        <div className="meetTheCar-textContent" style={{width: isMobile ? '100%' : '50%'}}>
          <h4 className="meetTheCar-subtitle">Dive into the Next Generation of Automotive Excellence</h4>
          <p>Welcome to an exclusive sneak peek into the future of mobility, where innovation meets elegance, and technology becomes one with design.</p>
        </div>
        <div className="meetTheCar-canvasContainer">
          <Canvas className="meetTheCar-canvas">
            <PerspectiveCamera makeDefault position={cameraPosition} />
            <ambientLight intensity={1.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={2} intensity={2} />
            <pointLight position={[10, 10, 10]} intensity={1.5} />
            <directionalLight position={[-1, 2, 4]} intensity={1.5} />
            <CarMesh />
            <OrbitControls enableZoom={false} />
            <Environment preset="sunset" background={false} />
          </Canvas>
        </div>
      </div>
    </div>
  );
}

export default MeetTheCar;
